import React, { useEffect, useRef, useState, useContext } from "react"
import tw from "twin.macro"
import SearchInput from "./SearchInput"
import SearchFilter from "./SearchFilter"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { FlightInfo } from "../Flights/Flights"
import SearchResults from "./SearchResults"
import FlightClient from "../../clients/FlightClient"

const HomeSearch: React.FC = () => {
  const [showResults, setShowResults] = useState(false)
  const [legType, setLegType] = useState<"A" | "D">("A")
  const homeSearch = useRef(null)
  const [flights, setFlights] = useState<FlightInfo[]>([])
  const searchFlightsText = "Search Flights"
  const [text, setText] = useState({ searchFlightsText })
  const { language } = useContext(LanguageContext)

  const handleClick = (e:any) => {
    if (!homeSearch?.current?.contains(e.target)) setShowResults(false)
  }

  useEffect(() => {
    window.addEventListener("click", handleClick, true)
    return () => window.removeEventListener("click", handleClick, true)
  }, [])

  const getFlights = async (searchText: string) => {
    setShowResults(false)
    try {
      const data = await FlightClient.getFlightData(legType)
      if(!data[0]) {
        setFlights([])
        setShowResults(true)
        return
      }
      const firstDate = getFirstDayOfFlights(data);
      const filteredSearchData = filterSearchText(firstDate, searchText)
      setFlights(filteredSearchData)
      setShowResults(true)
    } catch(err) {
      setFlights([])
      setShowResults(true)
    }
  }

  const getFirstDayOfFlights = (f: FlightInfo[]) => {
    const result = f?.reduce((groups:{[key:string]:FlightInfo[]}, flight:FlightInfo) => {
      const date = flight?.departure?.scheduled?.split("T")[0]
      if(!groups[date]) {
        groups[date] = []
      }
      groups[date].push(flight)
      return groups
    }, {})
    const dates = Object.keys(result).sort()
    return result[dates[0]]
  }

  const filterSearchText = (results: FlightInfo[], search: string) => {
    if(results?.length === 0) {
      return
    }
    if (search?.length < 1) {
      setShowResults(true)
      return results
    }
    const newFilteredFlights = results?.filter(flight => {
      return (
        flight?.airline.toLowerCase().includes(search?.toLowerCase()) ||
        flight?.via[0]?.name.toLowerCase().includes(search?.toLowerCase()) ||
        flight?.gate?.toLowerCase().includes(search?.toLowerCase()) ||
        flight?.flight_number?.full
          .toLowerCase()
          .includes(search?.toLowerCase()) ||
        flight?.status?.toLowerCase().includes(search?.toLowerCase())
      )
    })
    setShowResults(true)
    return newFilteredFlights
  }

  useEffect(() => {
    if (language === "en") {
      setText({ searchFlightsText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([searchFlightsText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div
      css={[
        tw`lg:pt-32 lg:pb-24 flex flex-col lg:flex-row justify-center bg-gradient-to-b from-blue-100 to-white`,
      ]}
    >
      <p
        css={[
          tw`pt-12 pl-6 lg:pl-0 pr-6 lg:pr-8 text-2xl lg:text-3xl font-extrabold`,
        ]}
      >
        {text?.searchFlightsText}
      </p>
      <div
        css={[tw`flex flex-col pb-12 lg:pb-0 relative mx-6 lg:mx-0`]}
        ref={homeSearch}
      >
        <SearchFilter
          setShowResults={setShowResults}
          legType={legType}
          setLegType={setLegType}
        />
        <SearchInput
          showResults={showResults}
          onSearch={getFlights}
        />
        <SearchResults
          filteredflights={[...flights]}
          showResults={showResults}
          legType={legType}
        /> 
      </div>
    </div>
  )
}

export default HomeSearch
