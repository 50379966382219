import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../Button/Button"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"
import { ImageSharp } from "../../templates/templates"

interface IBusinessCard {
  bgImageSrc: ImageSharp
  iconSrc: string
  iconAltText: string
  cta: {
    title: string
    url: string
  }
  description: string
  header: string
  hover: number | null
  setHover: React.Dispatch<React.SetStateAction<number | null>>
  index: number
}

const HomeBusinessCard: React.FC<IBusinessCard> = ({
  bgImageSrc,
  iconSrc,
  iconAltText,
  header,
  description,
  cta,
  hover,
  setHover,
  index,
}) => {
  const ctaText = cta.title ? cta.title : "Learn More"
  const [text, setText] = useState({ header, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ header, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([header, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <div
      key={header}
      css={[tw`mb-4 lg:mb-0 lg:ml-5 first-of-type:ml-0 lg:w-1/3 flex flex-col`]}
    >
      <Link
        to={cta?.url}
        aria-label={cta?.title}
        style={{ outlineColor: "#AED1D3", outlineOffset: "5px" }}
        onMouseOver={() => setHover(index)}
        onMouseLeave={() => setHover(null)}
      >
        <div
          css={[
            tw`text-white text-3xl h-60 xl:h-52 flex items-center relative transition-colors duration-300`,
          ]}
          style={{ backgroundColor: hover === index ? "#07827E" : "#263c5a" }}
        >
          <Image
            src={bgImageSrc?.localFile?.childImageSharp?.gatsbyImageData}
            publicURL={bgImageSrc?.localFile?.publicURL}
            alt=""
            css={[
              tw`w-full h-full absolute left-0 top-0 object-center object-cover opacity-20 saturate-0`,
            ]}
          />
          <div
            css={[
              tw`w-full h-full absolute left-0 top-0 z-1 opacity-0 transition-opacity bg-darkTeal`,
              hover === index && tw`opacity-70`,
            ]}
          />
          <div
            css={[
              tw`m-auto flex items-center justify-center flex-col w-3/5 relative z-2`,
            ]}
          >
            {iconSrc && (
              <Image alt={iconAltText} src={iconSrc} css={tw`w-24 h-16`} />
            )}
            <div css={[tw`pt-1 font-semibold transition-colors`]}>
              {text?.header}
            </div>
          </div>
        </div>
      </Link>
      <div css={[tw`hidden lg:block mt-3.5 text-base flex-grow font-medium`]}>
        {text?.description}
      </div>

      {cta?.url && (
        <Button
          ariaLabel={`learn more about ${header}`}
          styles={tw`mt-8`}
          link={cta?.url}
          text={ctaText}
        />
      )}
    </div>
  )
}

export default HomeBusinessCard
