import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"

export const SearchFilter: React.FC<{
  legType: "A" | "D"
  setLegType: React.Dispatch<React.SetStateAction<string>>
  setShowResults: any
}> = ({ setShowResults, legType, setLegType }) => {
  const arrivalsText = "Arrivals"
  const departuresText = "Departures"
  const [text, setText] = useState({ arrivalsText, departuresText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ arrivalsText, departuresText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          arrivalsText,
          departuresText,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const SearchOptionsData = [
    {
      name: text.arrivalsText,
      leg: "A",
    },
    {
      name: text.departuresText,
      leg: "D",
    },
  ]

  const handleClick = (value: string) => {
    setShowResults(false)
    setLegType(value)
  }

  return (
    <div css={[tw`flex pt-8 lg:pt-0 relative z-10`]}>
      {SearchOptionsData?.map(({ name, leg }, index) => (
        <button
          key={index}
          value={leg}
          onClick={() => handleClick(leg)}
          style={{ outlineColor: "#AED1D3", outlineOffset: "4px" }}
          css={[
            tw`min-w-[1/2] lg:min-w-[10rem] flex items-center rounded-t px-[1.375rem] py-2 mr-0.5 transition-all font-medium underline hover:no-underline hover:bg-teal hover:font-extrabold`,
            legType === leg && tw`bg-teal font-extrabold no-underline`,
          ]}
        >
          <span css={[tw`mx-auto`]}>{name}</span>
        </button>
      ))}
    </div>
  )
}

export default SearchFilter
