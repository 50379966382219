import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionHeading from "../SectionHeading/SectionHeading"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import InsideAirportCard from "./InsideAirportCard"
import { useInView } from "react-intersection-observer"

interface ICard {
  backgroundImage: any
  link: {
    title: string
    url: string
  }
}

interface IHomeInsideAirport {
  headline: string
  cards: ICard[]
}

const HomeInsideAirport: React.FC<IHomeInsideAirport> = ({
  headline,
  cards,
}) => {
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  const { ref, inView, entry } = useInView({triggerOnce: true })

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section>
      <div css={[tw`h-20 md:h-0 md:bg-white bg-blue-100 `]} />
      <div
        css={[
          tw`md:py-28 pb-0 md:bg-white bg-blue-100 px-7.75 md:px-18 m-auto lg:px-12 max-w-384`,
        ]}
      >
        <SectionHeading textStyles={[tw`min-w-60`]}>
          {text?.headline}
        </SectionHeading>
        <div
          css={[
            tw`flex flex-col md:flex-row md:flex-wrap items-center justify-between text-white pt-12`,
          ]}
          ref={ref}
        >
          {cards?.map(({ backgroundImage, link }, index) => {
            return (
              <InsideAirportCard
                backgroundImageSrc={backgroundImage}
                link={link}
                index={index}
                key={index}
                show={inView}
              />
            )
          })}
        </div>
      </div>
      <div css={[tw`h-20 md:h-0 md:bg-white bg-gradient-to-b from-blue-100`]} />
    </section>
  )
}

export default HomeInsideAirport
