import { IGatsbyImageData, GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { useInView } from "react-intersection-observer"
import { Image } from "../Image"
import { ImageSharp } from "../../templates/templates"
import Button from "../Button/Button"

export type InsideAirportCardProps = {
  backgroundImageSrc?: ImageSharp
  link?: {
    title: string
    url: string
  }
  index?: number
  show: boolean
}

const InsideAirportCard: React.FC<InsideAirportCardProps> = ({
  backgroundImageSrc,
  link,
  index,
  show,
}) => {
  const [hoverParent, setHoverParent] = useState(false)
  const [hoverChild, setHoverChild] = useState(null)

  const linkText = link.title
  const learnMoreText = "Learn More"
  const [text, setText] = useState({ linkText, learnMoreText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ linkText, learnMoreText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([linkText, learnMoreText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <Link
      onMouseEnter={() => setHoverChild(index)}
      onMouseLeave={() => setHoverChild(null)}
      onFocus={() => setHoverChild(index)}
      onBlur={() => setHoverChild(null)}
      to={link?.url}
      key={`${link?.url}${link?.title}`}
      className="group"
      css={[
        tw`relative text-white transition-all duration-300 text-5xl h-96 w-full md:w-1/2-calc lg:w-1/4-calc mt-4 first-of-type:mt-0 md:first-of-type:mt-4 lg:first-of-type:mt-0 lg:mt-0 z-10 overflow-hidden`,
        !show && hoverParent && hoverChild !== index && tw`opacity-80`,
        !show && tw`opacity-0 transform translate-y-1/2`,
      ]}
      style={{
        outlineColor: "#AED1D3",
        outlineOffset: "8px",
        animation: show && "insideAnimation 1s",
        animationIterationCount: show && 1,
        animationDuration:
          show && index === 0
            ? "0.3s"
            : show && index === 1
            ? "0.5s"
            : show && index === 2
            ? "0.7s"
            : "1s",
      }}
    >
      <Image
        alt=""
        aria-hidden
        src={backgroundImageSrc?.localFile?.childImageSharp?.gatsbyImageData}
        publicURL={backgroundImageSrc?.localFile?.publicURL}
        css={[
          tw`absolute h-full w-full  left-0 top-0 object-cover object-center`,
        ]}
      />
      <p
        css={[
          tw`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-1`,
        ]}
        style={{
          textShadow: "0 2px 8px rgba(0, 0, 0, 0.81)",
        }}
      >
        {text?.linkText}
      </p>
      <div
        css={[
          tw`absolute top-full left-1/2 -translate-x-1/2 transition-top duration-300 z-20`,
          hoverChild === index && tw`top-3/4`,
        ]}
        style={{
          maxWidth: "90%",
        }}
      >
        <Button
          styles={tw`group-hover:text-white group-hover:bg-left-full group-hover:no-underline group-focus:text-white group-focus:bg-left-full`}
          text={learnMoreText}
          tabIndex={-1}
          link={link?.url}
        />
      </div>
      <div
        css={[
          tw`opacity-0 bg-grayBlack h-full w-full transition-opacity duration-300`,
          hoverChild === index && tw`opacity-50`,
        ]}
      />
    </Link>
  )
}

export default InsideAirportCard
