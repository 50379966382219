import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { getImage } from "gatsby-plugin-image"
import HomeBusinessCard from "./HomeBusinessCard"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface ICard {
  backgroundImage: any
  cta: {
    title: string
    url: string
  }
  description: string
  header: string
  icon: any
}
interface IHomeBusiness {
  cards: ICard[]
  headline: string
}

const HomeBusiness: React.FC<IHomeBusiness> = ({ cards, headline }) => {
  const [hover, setHover] = useState<number | null>(null)
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ; (async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <section css={[tw`max-w-8xl m-auto`]}>
      <div css={[tw`h-20 md:h-0 md:bg-white bg-gradient-to-t from-blue-100`]} />
      <div css={[tw`bg-blue-100 md:bg-white px-7.75 md:px-18`]}>
        <SectionHeading>{text?.headline}</SectionHeading>
        <div
          css={[
            tw`flex flex-col lg:flex-row md:flex-wrap lg:flex-nowrap pt-10 text-center items-stretch`,
          ]}
        >
          {cards?.map(
            ({ backgroundImage, icon, header, description, cta }, index) => {
              if (!backgroundImage || !icon || !header || !description || !cta)
                return

              return (
                <HomeBusinessCard
                  key={`${cta?.url}${header}`}
                  bgImageSrc={backgroundImage}
                  iconSrc={icon?.localFile?.publicURL}
                  iconAltText={icon?.altText || ""}
                  header={header}
                  description={description}
                  cta={cta}
                  hover={hover}
                  setHover={setHover}
                  index={index}
                />
              )
            }
          )}
        </div>
      </div>
      <div css={[tw`h-20 md:h-0 md:bg-white bg-gradient-to-b from-blue-100`]} />
    </section>
  )
}

export default HomeBusiness
