import React from "react"
import tw from "twin.macro"

import Carousel from "../Carousel/Carousel"
import HighlightedPageLinks from "./HighlightedPageLinks"

interface ICarouselSlides {
  cta: {
    title: string
    url: string
  }
  header: string
  backgroundImage: any
  foregroundImage: any
}

interface IHomeCarousel {
  carouselSlides: ICarouselSlides[]
  highlightTextHeading: string
}

const HomeCarousel: React.FC<IHomeCarousel> = ({
  carouselSlides,
  highlightTextHeading,
}) => {
  return (
    <section css={[tw`text-center overflow-hidden bg-white md:bg-none bg-gradient-to-t from-blue-100 md:mt-18`]} aria-label="announcements carousel">
      <div css={[tw`h-20 md:h-0 md:bg-white bg-gradient-to-t from-blue-100`]} />
      <div
        css={[
          tw`flex sm:flex-row flex-col flex-wrap justify-center sm:text-5xl text-3xl font-bold pt-5 pb-10 sm:space-x-4 space-x-0 md:bg-white bg-blue-100`,
        ]}
      >
        {highlightTextHeading &&
          <HighlightedPageLinks title={highlightTextHeading} />
        }
      </div>
      <Carousel slides={carouselSlides} />
    </section>
  )
}

export default HomeCarousel
