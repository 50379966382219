import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import tw from "twin.macro"
import { BackgroundVideoProps } from "./BackgroundVideo.d"

const BackgroundVideo: React.FC<BackgroundVideoProps> = ({
  posterImageUrl,
  videoUrl,
}) => {
  const [videoSources, setVideoSources] = useState(null)

  const handler = () => {
    setVideoSources(videoUrl)
  }

  useEffect(() => {
    if (document.readyState === "complete") {
      handler()
    } else {
      window.addEventListener("load", handler)
    }
    return () => document.removeEventListener("load", handler)
  }, [])

  return (
    <>
      <video
        poster={posterImageUrl}
        aria-label="home hero video"
        autoPlay
        muted
        loop
        playsInline
        preload="none"
        css={[tw`absolute inset-0 object-cover object-center w-full h-[57rem] top-[-4.25rem]`]}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    </>
  )
}

export default BackgroundVideo
