import React, { useState, useEffect, useContext } from "react"
import tw from "twin.macro"
import Button from "../Button/Button"
import MenuIcons from "../MenuIcons/MenuIcons"
import BackgroundVideo from "../BackgroundVideo/BackgroundVideo"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")

interface IHero {
  data: {
    crown: string
    description: string
    headline: string
    videoFileUrl: {
      mediaItemUrl: string
    }
    heroPosterImageUrl: { mediaItemUrl: string }
    cta: {
      url: string
      target: string
      title: string
    }
  }
}

const HomeHero: React.FC<IHero> = ({ data }) => {
  const crown = data?.crown
  const description = data?.description
  const headline = data?.headline
  const [text, setText] = useState({ crown, description, headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ crown, description, headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          data.crown,
          data.description,
          data.headline,
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section
      css={[
        tw`text-white text-center h-[51.75rem] w-full overflow-hidden relative`,
      ]}
    >
      <BackgroundVideo
        videoUrl={data.videoFileUrl?.mediaItemUrl}
        posterImageUrl={data.heroPosterImageUrl?.mediaItemUrl}
      />
      <div css={[tw`absolute inset-0 flex flex-col justify-evenly`]}>
        <div
          css={[
            tw`flex flex-col text-xl tracking-wide px-10 md:px-0 justify-center flex-1`,
          ]}
        >
          <p css={[tw`text-base `]}>{text?.crown}</p>
          <h1
            css={[tw`text-5xl font-bold pt-3 pb-6`]}
            style={{ textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)" }}
          >
            {text?.headline}
          </h1>
          <p css={[tw`pb-2 text-base`]}>{text?.description}</p>
          {data.cta?.title && data.cta?.url && (
            <Button
              ariaLabel="Lean more about BHM destinations"
              styles={tw`mt-8 w-fit mx-auto`}
              link={data.cta?.url}
              text={data.cta?.title}
            />
          )}
        </div>
        <div
          css={[
            tw`flex flex-row justify-center gap-12 lg:gap-20 overflow-hidden items-start py-10 px-6`,
          ]}
        >
          <MenuIcons styling={tw`w-32 text-sm md:text-xl first-of-type:ml-0`} />
        </div>
      </div>
    </section>
  )
}

export default HomeHero
