import { Link } from "gatsby"
import React, { useState, useContext, useEffect, useMemo } from "react"
import tw from "twin.macro"
import { RightArrow } from "../Icons/RightArrow"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import FlightsTable from "../Flights/FlightsTable"
import { FlightInfo } from "../Flights/Flights"

const SearchResults: React.FC<{
  filteredflights: any
  showResults: boolean
  legType: "A"| "D"
}> = ({ filteredflights, showResults, legType }) => {

  const airlineText = "Airline"
  const cityText = "City"
  const flightText = "Flight"
  const scheduledTimeText = "Scheduled Time"
  const estimatedTimeText = "Estimated Time"
  const gateText = "Gate"
  const statusText = "Status"
  const viewAllArrivals = "View All Arrivals"
  const viewAllDepartures = "View All Departures"
  const noResultsText = "No results found"

  const [text, setText] = useState({
    airlineText,
    cityText,
    flightText,
    scheduledTimeText,
    estimatedTimeText,
    gateText,
    statusText,
    viewAllArrivals,
    viewAllDepartures,
    noResultsText
  })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({
        airlineText,
        cityText,
        flightText,
        scheduledTimeText,
        estimatedTimeText,
        gateText,
        statusText,
        viewAllArrivals,
        viewAllDepartures,
        noResultsText
      })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([
          airlineText,
          cityText,
          flightText,
          scheduledTimeText,
          estimatedTimeText,
          gateText,
          statusText,
          viewAllArrivals,
          viewAllDepartures,
          noResultsText
        ])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  const flightHeaders = [
    { name: text?.airlineText },
    { name: text?.cityText },
    { name: text?.flightText },
    { name: text?.scheduledTimeText },
    { name: text?.estimatedTimeText },
    { name: text?.gateText },
    { name: text?.statusText },
  ]

  return (
    <div
      id="searchResults"
      aria-hidden={!showResults}
      css={[
        tw`w-full absolute top-32 lg:top-24 z-10 rounded-b-md shadow-thick max-h-0 overflow-hidden transition-max-h ease-max-h-hide duration-max-h-hide`,
        showResults && tw`max-h-screen ease-max-h-show duration-max-h-show`,
      ]}
    >
      <div css={[tw`py-6 px-4 bg-white w-full`]}>
        <Link
          aria-hidden={!showResults}
          tabIndex={!showResults && -1}
          to={legType === "A" ? "/arrivals" : "/departures"}
          css={[
            tw`flex items-center justify-end text-xs font-bold text-blue-250 mb-2`,
          ]}
        >
          {legType === "A" ? text?.viewAllArrivals : text?.viewAllDepartures}
          <RightArrow styling={[tw`w-3 h-3 ml-1`]} color="#385885" />
        </Link>
        <div css={[tw`overflow-x-scroll`]}>
          {filteredflights?.length > 0 ?
            <FlightsTable flightHeaders={flightHeaders} flights={filteredflights} removeTopMargin smallText />
            :
            <span>{text?.noResultsText}</span>
          }
        </div>
      </div>
    </div>
  )
}

export default SearchResults
