import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

export type CarouselSlideHeaderProps = {
  header: string
}

const CarouselSlideHeader: React.FC<CarouselSlideHeaderProps> = ({
  header,
}) => {
  const [text, setText] = useState({ header })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ header })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([header])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <h3
      css={[tw`sm:text-6xl text-5xl font-extrabold sm:w-full w-72 mx-auto`]}
      style={{ textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)" }}
    >
      {text?.header}
    </h3>
  )
}

export default CarouselSlideHeader
