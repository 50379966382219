import React from "react"

const CarouselArrow: React.FC<{ option: string; styling?: any }> = ({
  option,
  styling,
}) => {
  return (
    <svg
      width="14"
      height="15"
      xmlns="http://www.w3.org/2000/svg"
      css={[styling]}
    >
      <path
        d={
          option === "next"
            ? "m7.759.791 4.871 6.12-4.113 6.986c-.17.245-.397.353-.617.353-.217 0-.442-.105-.61-.344a1.241 1.241 0 0 1-.214-.656c-.01-.245.043-.493.158-.694l2.82-3.993h-8.48c-.234 0-.454-.123-.61-.344A1.254 1.254 0 0 1 .75 7.5c0-.282.084-.535.214-.719.157-.22.377-.343.611-.343h8.479L7.289 2.53c-.228-.323-.275-.81-.128-1.195a.965.965 0 0 1 .01-.024 2 2 0 0 1 .012-.02c.19-.299.394-.446.576-.5z"
            : "M5.98.764.868 6.45l4.494 6.481a.82.82 0 0 0 .641.319c.228 0 .46-.1.634-.31.146-.175.228-.4.24-.63a1.105 1.105 0 0 0-.179-.669L3.671 8h9.208a.824.824 0 0 0 .634-.31 1.08 1.08 0 0 0 .24-.69c0-.27-.093-.514-.24-.69a.824.824 0 0 0-.634-.31H3.67l2.967-3.56c.13-.155.208-.349.233-.551a1.134 1.134 0 0 0-.09-.599c-.287-.394-.566-.52-.8-.526z"
        }
        fill="#FFF"
        stroke="#FFF"
        strokeWidth=".5"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CarouselArrow
