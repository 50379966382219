import React from "react"
import tw from "twin.macro"

import CarouselArrow from "../Icons/CarouselArrow"

interface ICarouselButton {
  slideLeft: () => void
  slideRight: () => void
  firstIndex: boolean
  lastIndex: boolean
}

const CarouselButton: React.FC<ICarouselButton> = ({
  slideLeft,
  slideRight,
  firstIndex,
  lastIndex,
}) => {
  const carouselArrowData = [{ direction: "previous" }, { direction: "next" }]

  if (firstIndex) {
    return (
      <button
        aria-label="next item"
        id="swiper-forward"
        type="button"
        css={[
          tw`lg:block hidden bg-white text-white w-10 sm:w-26 overflow-hidden h-16 bg-opacity-50 cursor-pointer transition rounded-l-full absolute right-0`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
        onClick={slideRight}
      >
        <CarouselArrow option="next" styling={[tw`m-auto `]} />
      </button>
    )
  } else if (lastIndex) {
    return (
      <button
        aria-label="previous item"
        id="swiper-back"
        type="button"
        css={[
          tw`lg:block hidden bg-white text-white w-10 sm:w-26 overflow-hidden h-16 bg-opacity-50 cursor-pointer transition rounded-r-full absolute left-0`,
        ]}
        style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
        onClick={slideLeft}
      >
        <CarouselArrow option="previous" styling={[tw`m-auto `]} />
      </button>
    )
  } else {
    return (
      <>
        {carouselArrowData?.map(({ direction }) => (
          <button
            aria-label={`${direction} item`}
            id={direction === "previous" ? "swiper-back" : "swiper-forward"}
            type="button"
            key={direction}
            css={[
              tw`lg:block hidden bg-white text-white w-10 sm:w-26 overflow-hidden h-16 bg-opacity-50 cursor-pointer transition`,
              direction === "previous"
                ? tw`rounded-r-full absolute left-0`
                : tw`rounded-l-full absolute right-0`,
            ]}
            style={{ outlineColor: "#AED1D3", outlineOffset: "8px" }}
            onClick={direction === "previous" ? slideLeft : slideRight}
          >
            <CarouselArrow
              option={direction === "previous" ? "previous" : "next"}
              styling={[tw`m-auto `]}
            />
          </button>
        ))}
      </>
    )
  }
}

export default CarouselButton
