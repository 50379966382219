import React from "react"
import tw from "twin.macro"
import CarouselHeroSlides from "./CarouselHeroSlides"

interface IHeroCarouselSlides {
  cta: {
    title: string
    url: string
  }
  headline: string
  headlineTwo: string
  headlineThree: string
  textAlignment: "left" | "center" | "right"
  textColor: "white" | "black"
  description: string
  backgroundImage: {
    sourceUrl: any
    localFile: {
      publicURL: string
    }
  }
}

interface IHomeCarousel {
  heroCarouselSlides: IHeroCarouselSlides[]
}

const HeroCarousel: React.FC<IHomeCarousel> = ({ heroCarouselSlides }) => {
  return (
    <section
      css={[
        tw`text-white text-center h-full lg:pb-0 w-full overflow-hidden relative mb-3 lg:mb-0`,
      ]}
      aria-label="hero home carousel"
    >
      <CarouselHeroSlides slides={heroCarouselSlides} />
    </section>
  )
}

export default HeroCarousel
