import React from 'react';
import tw from 'twin.macro';
import { FlightInfo } from './Flights';
import FlightsColumn from './FlightsColumn';

interface Props {
  flights: FlightInfo[]
  flightHeaders: any[]
  tableStyles?: any
  removeTopMargin?: boolean
  smallText?: boolean
}

const FlightsTable: React.FC<Props> = ({flights, flightHeaders, tableStyles, removeTopMargin, smallText}) => {

  return (
      <>
      {flights?.length > 0 && <h2 css={[tw`font-bold text-lg mt-20 text-blue-300 mb-2`, removeTopMargin && tw`mt-0`]}>{new Date(flights[0]?.departure?.scheduled).toDateString()}</h2>}
      <div css={[tw`w-full overflow-x-scroll scrollbar-thin scrollbar-thumb-blue-250 scrollbar-track-lightGray border-none`]}>
        <table
              id="flightsTable"
              css={[
                tw`flex flex-col justify-center items-center max-w-7xl min-w-[670px] mx-auto px-0 pb-0`,
                smallText && tw`min-w-[570px]`,
                tableStyles
              ]}
            >
              <thead
                css={[
                  tw`flex flex-row justify-center items-center w-full px-2 md:px-4 lg:px-12 py-3`,
                  smallText && tw`md:px-2 `
                ]}
                role={"row"}
                aria-selected={"true"}
              >
                {flightHeaders?.map(({ name }, i) => {
                  const upperCaseName = name.toUpperCase()
                  return (
                    <th
                      css={[tw`w-1/7 text-xs xl:text-sm font-extrabold text-flatBlack flex flex-col items-center justify-end`, smallText && tw`xl:text-xs`]}
                      key={i}
                    >
                      <span>{upperCaseName}</span>
                    </th>
                  )
                })}
              </thead>
              <tbody css={[tw`max-h-102 w-full overflow-y-scroll scrollbar-thin scrollbar-thumb-blue-250 scrollbar-track-lightGray border border-[#ccc]`]}>
              {flights?.map((flight, index) => {
                //Check dates, if there are no scheduled dates, put "N/A" to prevent blank spots
                if (flight?.departure?.scheduled == null) {
                  var schedTime = "N/A"
                } else {
                  const schedDate = new Date(flight?.departure?.scheduled)
                  var schedTime = schedDate.toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                  })
                }
                //Check dates, if there are no estimated times, use the scheduled time in its place
                if (flight?.departure?.estimated == null) {
                  var estTime = schedTime
                } else {
                  const estDate = new Date(flight?.departure?.estimated)
                  var estTime = estDate.toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                  })
                }
                return (
                    <tr
                      key={index}
                      css={[
                        tw`flex flex-row justify-center items-center w-full border-b-2 border-white hover:bg-teal px-2 md:px-4 lg:px-12`,
                        index % 2 ? tw`bg-altTableGray` : tw`bg-lightGray`,
                        smallText && tw`md:px-2`
                      ]}
                      role={"row"}
                      aria-selected={"true"}
                    >
                      <FlightsColumn smallText={smallText} text={flight?.airline} />
                      <FlightsColumn smallText={smallText} text={flight?.via[0].name} />
                      <FlightsColumn smallText={smallText} text={flight?.flight_number?.full} />
                      <FlightsColumn smallText={smallText} text={schedTime} />
                      <FlightsColumn smallText={smallText} text={estTime} />
                      <FlightsColumn smallText={smallText} text={flight?.gate} />
                      <FlightsColumn smallText={smallText} text={flight?.status} />
                    </tr>
                )
              })}
              </tbody>
            </table>
      </div>
      </>
  )
}

export default FlightsTable