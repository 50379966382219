import React from "react"

export const RightArrow: React.FC<{ styling?: any; color?: string }> = ({
  styling,
  color,
  ...remainingProps
}) => (
  <svg
    css={[styling]}
    style={{
      transition: "0.3s max-width cubic-bezier(0, 1, 0, 1), 0.3s margin",
    }}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...remainingProps}
  >
    <path
      d="m8.952.792 5.657 6.574-4.827 7.521a.852.852 0 0 1-.682.363.85.85 0 0 1-.675-.354 1.258 1.258 0 0 1-.248-.703 1.31 1.31 0 0 1 .183-.746l3.326-4.322H1.675A.853.853 0 0 1 1 8.771 1.268 1.268 0 0 1 .75 8c0-.302.097-.574.25-.771a.853.853 0 0 1 .675-.354h10.012L8.425 2.646a1.238 1.238 0 0 1-.241-.616 1.357 1.357 0 0 1 .102-.693l.015-.021c.216-.31.444-.467.651-.524z"
      fill={color ? color : "#9DD5EE"}
      stroke={color ? color : "#9DD5EE"}
      strokeWidth=".5"
      fillRule="evenodd"
    />
  </svg>
)
