import React from "react"
import tw from "twin.macro"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { Interpolation, Theme } from "@emotion/react"

interface Props {
  text: string;
  styles?: Interpolation<Theme>;
  smallText?: boolean
}

const FlightsColumn: React.FC<Props> = ({ text, styles, smallText }) => {
  
  return (
    <td
      css={[tw`w-1/4 md:w-1/7 py-3 text-center text-sm md:text-base lg:text-lg xl:text-xl font-medium text-grayBlack leading-normal`, styles, smallText && tw`md:text-sm`]}
    >
      {text}
    </td>
  )
}

export default FlightsColumn
