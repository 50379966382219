import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import Search from "../Icons/Search"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import SearchButton from "../Search/SearchButton"

const SearchInput: React.FC<{
  showResults: boolean
  onSearch: (searchText: string) => void
}> = ({ showResults, onSearch }) => {
  const [text, setText] = useState("")
  const [hideInputOutline, setHideInputOutline] = useState(false)

  const handleText = (e: any) => {
    setText(e?.target?.value)
  }

  const handleEnter = (e: any) => {
    if (e?.key === "Enter") {
      handleSearch()
    }
  }

  const handleSearch = () => {
    onSearch(text)
  }

  const placeholderText = "Search by flight number, city or airline"
  const [placeholder, setPlaceholder] = useState({ placeholderText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setPlaceholder({ placeholderText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([placeholderText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(placeholder, translations)
        setPlaceholder(translated)
      })()
    }
  }, [language])

  return (
    <div
      css={[
        tw`flex flex-col rounded-md transition-all duration-500 shadow-transparent focus:shadow-thick focus-within:shadow-thick`,
        showResults && tw`shadow-thick`,
      ]}
    >
      <div
        css={[
          tw`flex flex-row relative z-20 transition-all duration-500 border-b-transparent`,
          showResults && tw`rounded-b-none border-lightGray border-b-2`,
        ]}
      >
        <input
          type="text"
          name="Home Search"
          placeholder={placeholder?.placeholderText}
          value={text}
          onChange={handleText}
          onMouseDown={() => setHideInputOutline(true)}
          onBlur={() => setHideInputOutline(false)}
          onKeyDown={handleEnter}
          css={[
            tw`w-full lg:w-[32rem] pl-4 rounded-l-md text-xs lg:text-base transition-all duration-500 focus:outline-none`,
            showResults && tw`rounded-b-none`,
          ]}
        />
        <SearchButton
          handleClick={handleSearch}
          css={showResults && tw`rounded-b-none`}
        />
      </div>
    </div>
  )
}

export default SearchInput
