class FlightClient {
  public static getFlightData = async (legType: string): Promise<any> => {
    try {
      const res = await fetch(
        `${process.env.GATSBY_BAA_API_URL}/flight?filter=upcoming&legType=${legType}`,
        {
          method: "GET",
          headers: {
            "x-api-key": process.env.GATSBY_BAA_API_KEY,
          },
        }
      )
      return await res.json()
    } catch (err) {
      console.log(`error in retrieving flight data: ${err}`)
      return []
    }
  }
}

export default FlightClient
