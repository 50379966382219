import React, { useState } from "react"
import tw from "twin.macro"
import Button from "../Button/Button"

import {
  Pagination,
  Navigation,
  Autoplay,
  EffectFade,
  Swiper as ISwiper,
} from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import CarouselButton from "./CarouselButton"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CarouselSlideHeader from "./CarouselSlideHeader"
import { Image } from "../Image"

interface ISlides {
  cta: {
    title: string
    url: string
  }
  header: string
  backgroundImage: any
  foregroundImage: any
}

interface ICarousel {
  slides: ISlides[]
}

const Carousel: React.FC<ICarousel> = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [swiper, setSwiper] = useState<ISwiper>()

  const slideLeft = () => {
    if (swiper) {
      swiper.slidePrev()
    }
  }

  const slideRight = () => {
    if (swiper) {
      swiper.slideNext()
    }
  }

  return (
    <div css={[tw`md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto`]}>
      <Swiper
        modules={[Pagination, Navigation, Autoplay, EffectFade]}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        effect={"fade"}
        navigation={true}
        spaceBetween={50}
        slidesPerView={1}
        loop={false}
        onSwiper={swiper => setSwiper(swiper)}
        onActiveIndexChange={swiper => setActiveIndex(swiper?.activeIndex)}
        className="carousel"
        css={[tw`lg:mb-0 mb-10`]}
      >
        {slides?.map(
          ({ cta, header, backgroundImage, foregroundImage }, index) => {
            const backgroundImageSrc = getImage(backgroundImage?.localFile)
            if (!backgroundImageSrc) return
            return (
              <SwiperSlide key={`home-carousel-slide-${index}`}>
                <div css={[tw`relative mb-8`]}>
                  <Image
                    src={backgroundImageSrc}
                    publicURL={backgroundImage?.localFile?.publicURL}
                    aria-hidden
                    alt=""
                    css={[tw`w-full`, "aspect-ratio: 16/8;"]}
                  />
                  {/* <div
                    css={[
                      tw`bg-extraDarkGray opacity-30 absolute left-0 top-0 z-0 h-full w-full`,
                    ]}
                  /> */}
                  <div
                    css={[
                      tw`w-full text-white absolute top-0 left-0 h-full flex flex-col justify-center items-center`,
                    ]}
                  >
                    {header && <CarouselSlideHeader header={header} />}
                    {foregroundImage?.localFile?.publicURL && (
                      <Image
                        src={foregroundImage?.localFile?.publicURL}
                        alt=""
                        aria-hidden
                        css={[
                          tw`block m-auto mt-5 sm:mb-0 mb-7 w-auto max-w-3/4-screen sm:max-w-80 h-20`,
                        ]}
                      />
                    )}
                    {slides?.length > 1 && (
                      <CarouselButton
                        firstIndex={activeIndex === 0}
                        lastIndex={activeIndex === slides?.length - 1}
                        slideLeft={slideLeft}
                        slideRight={slideRight}
                      />
                    )}
                  </div>
                </div>
                {cta && (
                  <Button
                    ariaLabel={`about ${header}`}
                    link={cta?.url}
                    text={cta?.title}
                  />
                )}
              </SwiperSlide>
            )
          }
        )}
      </Swiper>
    </div>
  )
}

export default Carousel
